import React from 'react';

import styles from './Hero.module.css';

export const Hero = ({ title, subtitle }) => (
  <div className={styles.hero}>
    <h1 className={styles.title}>{title}</h1>
    <h2 className={styles.subtitle}>{subtitle}</h2>
  </div>
);
