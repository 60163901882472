import React from 'react';

import styles from './QRCodeBox.module.css';

export const DownloadButton = ({ children, onDownload, ...otherProps }) => {
  const onClick = e => {
    e.preventDefault();

    onDownload(otherProps);
  };

  return (
    <button type="button" className={styles.downloadButton} onClick={onClick}>
      {children}
    </button>
  );
};