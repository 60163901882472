import React from 'react';
import styles from './Form.module.css';

export const FormStep = ({ number, hidden, hideIndicator, children, disabled }) => {
  return !hidden && (
    <fieldset disabled={disabled} className={styles.formStep}>
      {!hideIndicator && <i className={styles.formStepIndicator}>{number}</i>}
      {children}
    </fieldset>
  );
};

FormStep.defaultProps = {
  disabled: false,
};
