import React, { useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { sendSignInLink } from '../../utils/AuthUtils';
import { TextInput, validEmail, formStyles } from '../Form';
import { Modal } from '../Modal';
import { DataSection } from '../DataSection';
import { SignUpSuccessMessage } from './SignUpSuccessMessage';

import googleLogo from '../../assets/google-logo.svg';

import styles from './SignUpModal.module.css';

export const SignUpModal = ({ open, onClose, onSignInUsingGoogle, errors}) => {
  const [sent, setSent] = useState(false);
  const onSubmit = async ({ email }) => {
    try {
      await sendSignInLink(email);
      setSent(true);
    } catch (e) {
      return { email: e.message };
    }
  };
  const onSignInUsingGoogleClick = e => {
    e.preventDefault();
    onSignInUsingGoogle();
  };

  return (
    <Modal className={styles.modal} open={open} onClose={onClose} title="Sign Up / Sign In">
      <button onClick={onSignInUsingGoogleClick} className={styles.signInButton}>
        <img className={styles.vendorLogo} src={googleLogo} alt="Google logo" /> Sign in with Google
      </button>
      {errors && errors.google && (
        <p className={formStyles.formError}>{errors.google}</p>
      )}
      <hr className={styles.divider} />
      <h4 className={styles.title}>Sign In With Email</h4>
      <FinalForm
        onSubmit={onSubmit}
        render={({ form, handleSubmit, reset, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            {!sent ? (
              <DataSection>
                <Field
                  name="email"
                  placeholder="Email"
                  className={styles.emailInput}
                  errorClassName="noIcon"
                  validate={validEmail}
                  component={TextInput}
                  disabled={submitting}
                />
                <button type="submit" className={styles.submitButton} disabled={submitting}>
                  {submitting? 'Sending...' : 'Send'}
                </button>
                <p className={formStyles.formHint}>
                  You will receive an email containing a link, which you can click to sign in. No password is required.
                </p>
              </DataSection>
            ) : (
              <SignUpSuccessMessage email={values.email} />
            )}
          </form>
        )} />
    </Modal>
  );
};
