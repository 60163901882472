import React, { useContext } from 'react';
import { Link, useNavigate } from '@reach/router';
import { UserContext } from '../App';
import { Spinner } from '../Spinner';
import { formStyles } from '../Form';

import styles from './ProPage.module.css';

export const ProPage = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const onNewClick = e => {
    e.preventDefault();
    navigate('/new-qr-pro');
  };

  if (user === undefined) {
    return null;
  }

  return (
    <div className={styles.proPage}>
      {user === null || (user && !user.pro) ? (
        <p><Link to="/apply-pro">Apply</Link> for a pro account.</p>
      ) : (
        <div>
          <button className={formStyles.primaryButton} onClick={onNewClick}>New QR code</button>
        </div>
      )}
    </div>
  );
};
