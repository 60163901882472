import 'whatwg-fetch';

const getBaseUrl = () => {
  const { userAgent } = window.navigator;

  if (userAgent.includes('Chrome')) {
    return 'https://europe-west2-hyper-link-shortner.cloudfunctions.net/widgets';
  }

  return '';
}
const BASE_URL =
  process.env.NODE_ENV === 'development' ?
    'http://localhost:5001/hyper-link-shortner/europe-west2/widgets'
    : getBaseUrl();

export const post = async (url, data, options) => {
  const response = await fetch(`${BASE_URL}${url}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': window.location.origin,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
    ...options
  });

  return await response.json();
};

export const get = async (url) => {
  const response = await fetch(`${BASE_URL}${url}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': window.location.origin,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
};

export const put = async (url, data, options) => {
  const response = await fetch(`${BASE_URL}${url}`, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': window.location.origin,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
    ...options
  });

  return await response.json();
};

export const del = async (url, data, options) => {
  const response = await fetch(`${BASE_URL}${url}`, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': window.location.origin,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
    ...options
  });

  try {
    const res = await response.json();
    return res;
  } catch (e) {
    return response;
  }
};
