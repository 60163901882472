const linkRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneNumberRegex = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/;
const postcodeRegex = /^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/;

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const required = value => (value ? undefined : 'Required');

export const minLength = (fieldName, length) => value =>
  (!value || value.length < length ? 
    `${fieldName} must be at least ${length} symbol${length > 1 ? 's' : ''} long`
    : undefined
  );

export const maxLength = length => value =>
  (value && value.length > length ? `Maximum length of ${length} symbols exceeded` : undefined);

export const validUrl = value => (linkRegex.test(value) ? undefined : 'Invalid URL');
export const validEmail = value => (emailRegex.test(value) ? undefined : 'Invalid email');
export const validPhoneNumber = value => (phoneNumberRegex.test(value) ? undefined : 'Invalid phone number');
export const validPostcode = value => (postcodeRegex.test(value) ? undefined : 'Invalid postcode');
export const validZooplaLink = value => (!value || value.includes('zoopla.co.uk') ? undefined : 'Invalid Zoopla link');
export const validRightmoveLink = value => (!value || value.includes('rightmove.co.uk') ? undefined : 'Invalid Rightmove link');

export const maxValue = max => value => value > max ? `Maximum allowed size is ${max}px` : undefined;
export const minValue = min => value => value < min ? `Size must be at least ${min}px` : undefined;

