import React from 'react';
import { DataSection } from '../DataSection';
import { ReactComponent as CheckCircle } from '../../assets/check-circle.svg';

import styles from './SignUpModal.module.css';

export const SignUpSuccessMessage = ({ email, className }) => (
  <DataSection className={className}>
    <CheckCircle className={styles.checkCircle} />
    <p>A sign-in email with additional instructions was sent to <b>{email}</b>. Check your email to complete sign-in.</p>
  </DataSection>
);
