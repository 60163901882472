import React from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Modal } from '../Modal';
import { TextInput, required } from '../Form';
import { DataSection } from '../DataSection';

import styles from './ConfirmModal.module.css';

export const ConfirmModal = ({ onConfirm, open, onClose }) => (
  <Modal open={open} onClose={onClose} title="Please confirm your email">
    <FinalForm
      onSubmit={onConfirm}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <DataSection>
            <Field
              name="email"
              placeholder="Email"
              className={styles.emailInput}
              errorClassName="noIcon"
              validate={required}
              component={TextInput}
            />
            <button type="submit" className={styles.submitButton}>Confirm</button>
          </DataSection>
        </form>
      )} />
  </Modal>
);
