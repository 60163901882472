import React, { useEffect, useState, useRef, useContext } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import cn from 'classnames';
import confetti from 'canvas-confetti';
import { post } from '../../services/HTTPService';
import { UserContext } from '../App';
import { Hero } from '../Hero';
import { Spinner } from '../Spinner';
import { PageDetails } from '../PageDetails';
import { QRCodeBox } from '../QRCodeBox';
import { ShortLinkInput } from '../ShortLinkInput';
import { DataSection } from '../DataSection';
import { FormStep } from './FormStep';
import { composeValidators, minLength, maxLength, validUrl } from './validators';

import styles from './Form.module.css';

const scrollToBottom = () => {
  setTimeout(() => {
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }, 100);
};

export const Form = ({ onSignIn, savedState, initialValues }) => {
  const [step, setStep] = useState(1);
  const [prepareResponse, setPrepareResponse] = useState({});
  const [submitResponse, setSubmitResponse] = useState({});
  const user = useContext(UserContext);
  const urlIdInputRef = useRef(null);
  const formRef = useRef(null);
  const onPrepare = async (values, formApi) => {
    try {
      const res = await post('/prepare', values);

      if (res.error) {
        return { url: res.error }; 
      }

      if (prepareResponse.urlId) {
        formApi.initialize(res);
      }

      setPrepareResponse(res);
      setStep(2);
    } catch (e) {
      return { url: e }; 
    }
  };
  const onSubmit = async (values, formApi) => {
    const data = { ...prepareResponse, ...values };

    if (values.url !== prepareResponse.url) {
      setPrepareResponse({});
      return onPrepare(values, formApi);
    }

    try {
      const res = await post('/shortlink', data);

      if (res.error) {
        return { urlId: res.error }; 
      }

      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
      
      setSubmitResponse(res);
      setStep(3);
      scrollToBottom();
    } catch (e) {
      console.log(e);
      return { urlId: e.message }; 
    }
  };
  
  const onSignInClick = values => e => {
    localStorage.setItem('savedFormState', JSON.stringify({
      formValues: values,
      prepareResponse,
      step,
    }));
    onSignIn(e);
  };
  const animatedHintRef = useRef(null);
  const onUrlIdClick = () => {
    if (user || !animatedHintRef.current || animatedHintRef.current.classList.contains(styles.animatedHint)) {
      return;
    }

    animatedHintRef.current.classList.add(styles.animatedHint);
    setTimeout(() => {
      animatedHintRef.current.classList.remove(styles.animatedHint);
    }, 1500);
  };
  const onUndoClick = formApi => e => {
    e.preventDefault();
    formApi.change('urlId', prepareResponse.urlId);
  };
  const isUrlChangedAfterSubmit = urlValue => {
    return prepareResponse && urlValue !== prepareResponse.url;
  };
  const submitHandler = async values => (
    values.url && !isUrlChangedAfterSubmit(values.url) ? await onSubmit(values) : await onPrepare(values)
  );

  useEffect(() => {
    if (savedState) {
      setPrepareResponse(savedState.prepareResponse);
      setStep(savedState.step);
      setTimeout(() => urlIdInputRef.current && urlIdInputRef.current.focus(), 100);
    }
  }, [savedState]);

  return (
    <FinalForm
      onSubmit={submitHandler}
      initialValues={initialValues}
    >
      {({ form, handleSubmit, submitting, values, pristine, invalid, validating }) => (
        <div className={styles.formContainer}>
          <Hero
            title="Need to generate a QR code for free?"
            subtitle="Simply enter your link below."
          />
          <form onSubmit={handleSubmit} className={styles.form} ref={formRef}>
            <FormStep number="01" hideIndicator={!prepareResponse.urlId} disabled={submitResponse.urlId}>
              <Field name="url" validateFields={[]} validate={composeValidators(minLength('URL', 4), maxLength(1024), validUrl)}>
                {({ input, meta: { error, submitError, touched } }) => (
                  <div className={cn(styles.heroFormRow, (error || submitError) && touched && styles.hasError)}>
                    <input {...input} className={styles.input} type="text" />
                    <button type="submit" className={styles.primaryButton} disabled={submitting}>Generate Now</button>
                    {(error || submitError) && touched && (
                      <div className={styles.formError}>{error || submitError}</div>
                    )}
                  </div>
                )}
              </Field>
            </FormStep>
            <FormStep number="02" hidden={step < 2} disabled={isUrlChangedAfterSubmit(values.url) || submitResponse.urlId}>
              <h3 className={styles.formTitle}>Set up your short URL link</h3>
              <DataSection>
                <ShortLinkInput
                  formApi={form}
                  initialValue={prepareResponse.urlId}
                  onClick={onUrlIdClick}
                  inputRef={urlIdInputRef}
                />
                {!user && (
                  <div className={styles.formHint} ref={animatedHintRef}>
                    <a onClick={onSignInClick(values)} href="/sign-up">Sign up</a> if you want to use custom link IDs and get access to statistics.
                  </div>
                )}
              </DataSection>
              <PageDetails
                response={prepareResponse}
                formApi={form}
                submitting={submitting}
                values={values}
              />
              <div className={styles.formButtonsRow}>
                <button
                  type="submit"
                  className={styles.primaryButton}
                  disabled={submitting || invalid || validating || pristine}
                >
                  Create
                </button>
              </div>
            </FormStep>
            <Spinner visible={submitting} />
          </form>
          <FormStep number="03" hidden={step < 3} disabled={isUrlChangedAfterSubmit(values.url)}>
            <h3 className={styles.formTitle}>Hooray! Your shiny new QR code has been generated!</h3>
            <QRCodeBox
              svgString={submitResponse.qrCode}
              shortLink={submitResponse.shortLink}
              urlId={submitResponse.urlId}
            />
          </FormStep>
        </div>
      )}
    </FinalForm>
  );
};
