import { useState } from 'react';
import { get, post } from '../services/HTTPService';
import { errorNotification } from '../components/Notification';

const DEFAULTS = {
  showErrorNotification: true,
};
const GET = 'GET';
const POST = 'POST';

const send = {
  [GET]: get,
  [POST]: post,
};

const useRequest = (method, url, options = DEFAULTS) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const request = async data => {
    setLoading(true);

    try {
      const res = await send[method](url, data);

      if (res.error) {
        throw new Error(res.error);
      }

      setResult(res);
    } catch(e) {
      options.showErrorNotification && errorNotification(e.message);
      setError(e.message);
    }

    setLoading(false);
  };
  
  return [request, loading, result, error];
};

export const useGetRequest = (url, options) => useRequest(GET, url, options);
export const usePostRequest = (url, options) => useRequest(POST, url, options);
