import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Cookies from 'js-cookie';
import { Router, Link } from '@reach/router';
import { post, get } from '../../services/HTTPService';
import { SignUpModal, SignUpSuccessMessage } from '../SignUpModal';
import { ConfirmModal } from '../ConfirmModal';
import { Spinner } from '../Spinner';
import { Form } from '../Form';
import { Notification, errorNotification } from '../Notification';
import { UserQRCodesPage } from '../UserQRCodesPage';
import { QRCodePage } from '../QRCodePage';
import { ProPage } from '../ProPage';
import { ApplyForPro } from '../ApplyForPro';
import { ProQRForm } from '../ProQRForm';
import { ReactComponent as Logo } from '../../assets/qrbox-logo.svg';
import styles from './App.module.css';

const getSavedFormState = () => {
  const values = localStorage.getItem('savedFormState');
  return values ? JSON.parse(values) : null;
};

export const UserContext = React.createContext();

export const App = () => {
  const { href } = window.location;
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmNeeded, setConfirmNeeded] = useState(false);
  const [user, setUser] = useState(undefined);
  const [savedFormState, setSavedFormState] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [signInErrors, setSignInErrors] = useState({});
  const onModalClose = () => setModalOpen(false);
  // email sign in strategy
  const signInWithEmail = async email => {
    const { user } = await firebase.auth().signInWithEmailLink(email, href);
    localStorage.removeItem('emailForSignIn');
    
    const idToken = await user.getIdToken();
    // const csrfToken = Cookies.get('csrfToken');
    
    try {
      await post('/sign-in', { idToken });
      setSavedFormState(getSavedFormState())
      localStorage.removeItem('savedFormState');
      window.history.pushState({}, null, '/');
    } catch (e) {
      console.log('error', e);
      throw e;
    }
  };
  const checkForSignIn = async () => {
    if (!firebase.auth().isSignInWithEmailLink(href)) {
      return;
    }
    const savedEmail = localStorage.getItem('emailForSignIn');

    if (savedEmail) {
      try {
        setShowSpinner(true);
        await signInWithEmail(savedEmail);
      } catch (e) {
        console.log(e);
      }
      setShowSpinner(false);
      return;
    }

    setConfirmNeeded(true);
    setModalOpen(true);
  };
  const onConfirm = async ({ email }) => {
    setShowSpinner(true);
    try {
      await signInWithEmail(email);
      setModalOpen(false);
      setConfirmNeeded(false);
    } catch (e) {
      return { email: e.message };
    }
    setShowSpinner(false);
  };
  const onSignInClick = e => {
    e.preventDefault();
    setModalOpen(true);
  };
  const onSignOutClick = async e => {
    setShowSpinner(true);
    e.preventDefault();
    await firebase.auth().signOut();
    await post('/sign-out');
    window.location.assign('/');
  };
  const onSignInUsingGoogle = async () => {
    setShowSpinner(true);
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const { user } = await firebase.auth().signInWithPopup(provider);
      const idToken = await user.getIdToken();

      await post('/sign-in', { idToken });
      localStorage.removeItem('savedFormState');
      setModalOpen(false);
    } catch (e) {
      const { code, message, email, credential } = e;
      setSignInErrors({ google: message });
    }
    setShowSpinner(false);
  };
  const fetchProAccount = async uid => {
    setShowSpinner(true);
    try {
      const res = await get(`/pro-account/${uid}`);

      if (res.error) {
        throw new Error(res.error);
      }

      setUser({ ...user, ...res, pro: true });
    } catch(e) {
      if (e.message !== 'Not found') {
        errorNotification(e.message);
      }
    }

    setShowSpinner(false);
  };

  useEffect(() => {
    checkForSignIn();
    firebase.auth().onAuthStateChanged(user => {
      setUser(user);

      if (user) {
        fetchProAccount(user.uid);
      }
    });
  }, []);

  return (
    <div className={styles.app}>
      <UserContext.Provider value={user}>
        <header className={styles.header}>
          <div className={styles.logo}>
            <Link to="/"><Logo /></Link>
          </div>
          <div className={styles.authBox}>
            <menu type="toolbar" className={styles.menu}>
              {user ? (
                <>
                  <Link to="/my-qr-codes">My QR codes</Link>
                  <a onClick={onSignOutClick} href="/sign-out">Sign out</a>
                </>
              ) : user === null ? (
                <a onClick={onSignInClick} href="/sign-in">Sign in</a>
              ) : null}
            </menu>
          </div>
        </header>
        <Router className={styles.main}>
          <Form
            path="/"
            onSignIn={onSignInClick}
            savedState={savedFormState}
            initialValues={savedFormState && savedFormState.formValues}
          />
          <UserQRCodesPage path="/my-qr-codes" onSignIn={onSignInClick} />
          <QRCodePage path="/qr-code/:urlId" />
          <ProPage path="/pro" />
          <ApplyForPro path="/apply-pro" />
          <ProQRForm path="new-qr-pro" />
        </Router>
        <footer className={styles.footer}>
          <p>Best QR codes on the internet. British scientists approved <span role="img" aria-label="british flag">🇬🇧🔬✅</span>.</p>
        </footer>
        {confirmNeeded ? (
          <ConfirmModal onConfirm={onConfirm} open={modalOpen} onClose={onModalClose} />
        ) : (
          <SignUpModal
            open={modalOpen}
            onClose={onModalClose}
            onSignInUsingGoogle={onSignInUsingGoogle}
            errors={signInErrors}
          />
        )}
        <Spinner visible={showSpinner} />
        <Notification />
      </UserContext.Provider>
    </div>
  );
};
