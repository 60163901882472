import React from 'react';
import { ToastContainer, Slide, toast } from 'react-toastify';

import styles from './Notification.module.css';

const CloseButton = ({ closeToast }) => {
  const onClick = e => {
    e.preventDefault();
    closeToast();
  };
  return <a href="/" onClick={onClick}>Close</a>;
};

export const Notification = () => (
  <ToastContainer
    className={styles.toastContainer}
    toastClassName={styles.toast}
    bodyClassName={styles.toastBody}
    autoClose={5000}
    hideProgressBar
    closeButton={<CloseButton />}
    transition={Slide}
  />
);

export const successNotification = (text, options = {}) => toast(text, {
  className: styles.success,
  bodyClassName: styles.successBody,
  ...options,
});

export const errorNotification = (text, options = {}) => toast(text, {
  className: styles.error,
  bodyClassName: styles.errorBody,
  ...options,
});
