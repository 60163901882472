import React, { useCallback, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import isUrl from 'is-url';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import { Editor, Transforms, Range, createEditor } from 'slate';
import { withHistory } from 'slate-history';

import { SmallSpinner } from '../SmallSpinner';
import { Toolbar, ToolbarButton } from './Toolbar';

import styles from './RichTextEditor.module.css';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['bulleted-list', 'numbered-list'];

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, { match: n => n.type === 'link' });
  return !!link;
};

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' });
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link':
      return (
        <a {...attributes} href={element.url}>
          {children}
        </a>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ToolbarButton
      icon={icon}
      active={isBlockActive(editor, format)}
      onClick={() => toggleBlock(editor, format)}
    />
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ToolbarButton
      icon={icon}
      active={isMarkActive(editor, format)}
      onClick={() => toggleMark(editor, format)}
    />
  );
};

const LinkButton = () => {
  const editor = useSlate();
  const onClick = () => {
    const url = window.prompt('Enter the URL of the link:');
  
    if (!url) {
      return;
    }

    insertLink(editor, url);
  };

  return (
    <ToolbarButton
      icon="link"
      active={isLinkActive(editor)}
      onClick={onClick}
    />
  );
};

const withLinks = editor => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = data => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const RichTextEditor = ({
  input,
  meta,
  isZooplaActive,
  onZooplaClick,
  isRightmoveActive,
  onRightmoveClick,
  loading,
}) => {
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(withLinks(createEditor()))), []);
  const onKeyDown = e => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, e)) {
        e.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }
  };

  return (
    <div className={styles.richTextEditor}>
      <Slate editor={editor} value={input.value} onChange={input.onChange}>
        <Toolbar>
          <MarkButton icon="bold" format="bold" />
          <MarkButton icon="italic" format="italic" />
          <LinkButton />
          <BlockButton icon="heading1" format="heading-one" />
          <BlockButton icon="heading2" format="heading-two" />
          <BlockButton icon="heading3" format="heading-three" />
          <BlockButton icon="unordered-list" format="bulleted-list" />
          <BlockButton icon="ordered-list" format="numbered-list" />
          <ToolbarButton
            icon="zoopla"
            title="Fetch property details from Zoopla"
            onClick={onZooplaClick}
            disabled={!isZooplaActive}
          />
          <ToolbarButton
            icon="rightmove"
            title="Fetch property details from Rightmove"
            onClick={onRightmoveClick}
            disabled={!isRightmoveActive}
          />
        </Toolbar>
        <Editable
          placeholder="Enter some rich text…"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          spellCheck
        />
      </Slate>
      <SmallSpinner visible={loading} showOverlay>Requesting property details</SmallSpinner>
    </div>
  );
};
