import React from 'react';
import cn from 'classnames';

import styles from './DataSection.module.css';

export const DataSection = ({ title, children, className }) => (
  <section className={cn(styles.section, className)}>
    {title && <h4 className={styles.header}>{title}:</h4>}
    {children}
  </section>
);
