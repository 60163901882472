import firebase from 'firebase';

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: 'https://qrbox.uk/',
  // This must be true.
  handleCodeInApp: true,
};

export const sendSignInLink = async email => {
  const res = await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);

  localStorage.setItem('emailForSignIn', email);

  return res;
};
