import React from 'react';
import cn from 'classnames';
import { Error as ErrorMessage } from '../Message';

import styles from './Form.module.css';

export const Textarea = ({ input, meta, className, errorClassName, ...other }) => {
  const { error, submitError, touched } = meta;
  const invalid = Boolean((error || submitError) && touched);

  return (
    <div className={styles.inputContainer}>
      <textarea
        {...input}
        {...other}
        className={cn(styles.textarea, className, invalid && styles.invalid)}
      />
      {invalid && (
        <ErrorMessage className={errorClassName}>
          {error || submitError}
        </ErrorMessage>
      )}
    </div>
  )
};
