import React, { useState, useEffect, useRef, useContext } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import cn from 'classnames';
import { post } from '../../services/HTTPService';
import { UserContext } from '../App';
import { formStyles, minValue, maxValue, composeValidators } from '../Form';
import { successNotification, errorNotification } from '../Notification';
import { DownloadButton } from './DownloadButton';

import { ReactComponent as LockIcon } from '../../assets/lock.svg';
import { ReactComponent as TickIcon } from '../../assets/tick.svg';
import { ReactComponent as CrossIcon } from '../../assets/cross.svg';
import styles from './QRCodeBox.module.css';

const getBase64SvgUrl = svgString => {
  const buffer = new Buffer(svgString);
  return `data:image/svg+xml;base64,${buffer.toString('base64')}`;
};

const getBase64SvgUrlWithSize = (svgString, size) => {
  const tail = svgString.split('<svg');
  const svgStringWithSize = [`<svg width="${size}" height="${size}"`, ...tail].join('');
  return getBase64SvgUrl(svgStringWithSize);
};

const downloadFile = (filename, href) => {
  const link = document.createElement('a');
  link.download = filename;
  link.href = href;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const QRCodeBox = ({ svgString, shortLink, urlId }) => {
  const [svgUrl, setSvgUrl] = useState('');
  const [editMode, setEditMode] = useState(false);
  const canvasRef = useRef(null);
  const shortUrlRef = useRef(null);
  const user = useContext(UserContext);
  const onSubmit = async values => {
    try {
      const res = await post('/send-to-email', { ...values, urlId });

      if (res.size) {
        setEditMode(true);
      } else {
        successNotification('Email with your QR code has been sent');
      }

      return res;
    } catch (e) {
      errorNotification(e.message);
    }
  };
  const onChangeSizeClick = e => {
    e.preventDefault();
    setEditMode(true);
  };
  const onSave = e => {
    e.preventDefault();
    setEditMode(false);
  };
  const onCancel = (formApi, initial) => e => {
    formApi.change('size', initial);
    formApi.resetFieldState('size');
    e.preventDefault();
    setEditMode(false);
  };
  
  const onDownload = ({ size, format, quality }) => {
    const ctx = canvasRef.current.getContext('2d');
    const svgImage = new Image(size, size);
    const extention = format.split('/').pop();
    canvasRef.current.width = size;
    canvasRef.current.height = size;

    svgImage.onload = () => {
      ctx.drawImage(svgImage, 0, 0, size, size);

      const href = canvasRef.current.toDataURL(format, quality).replace(format, 'image/octet-stream');
      downloadFile(`qr-code-${urlId}.${extention}`, href);
    };
    
    svgImage.src = getBase64SvgUrlWithSize(svgString, size);
  };
  const onDownloadSvg = ({ size, format }) => {
    const fileName = `qr-code-${urlId}.${format}`;
    const href = getBase64SvgUrlWithSize(svgString, size);

    downloadFile(fileName, href);
  };
  const onCopy = e => {
    e.preventDefault();
    shortUrlRef.current.select();
    shortUrlRef.current.setSelectionRange(0, 99999);
    document.execCommand('copy');
  };

  useEffect(() => {
    setSvgUrl(getBase64SvgUrl(svgString));
  }, [svgString]);

  return svgUrl ? (
    <div className={styles.qrCodeBox}>
      <FinalForm
        onSubmit={onSubmit}
        render={({ form, handleSubmit, submitting, values }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.sizeBox}>
              <Field name="size" validateFields={[]} initialValue={1080} validate={composeValidators(minValue(37), maxValue(2160))}>
                {({ input, meta: { initial, error, submitError, touched } }) => editMode ? (
                  <fieldset className={cn(styles.sizeFieldset, (error || submitError) && touched && formStyles.hasError)}>
                    <label className={styles.sizeLegend}>Size:</label>
                    <div className={styles.sizeInputGroup}>
                      <label className={styles.sizeLabel}>W:</label>
                      <input
                        {...input}
                        className={styles.sizeInput}
                        type="number"
                      />
                    </div>
                    <LockIcon className={styles.lockIcon} />
                    <div className={styles.sizeInputGroup}>
                      <label className={styles.sizeLabel}>H:</label>
                      <input
                        {...input}
                        className={styles.sizeInput}
                        type="number"
                      />
                    </div>
                    <button
                      type="button"
                      className={styles.sizeSaveButton}
                      onClick={onSave}
                      disabled={error || (submitError && !touched)}
                    >
                      <TickIcon className={styles.tickIcon} />
                    </button>
                    <button
                      type="button"
                      className={styles.sizeCancelButton}
                      onClick={onCancel(form, initial)}
                    >
                      <CrossIcon className={styles.crossIcon} />
                    </button>
                    {(error || submitError) && touched && (
                      <div className={formStyles.formError}>{error || submitError}</div>
                    )}
                  </fieldset>
                ) : (
                  <p>Size: {input.value}px x {input.value}px <a onClick={onChangeSizeClick} href="/change-size">Change</a></p>
                )}
              </Field>
            </div>
            <div className={styles.previewContainer}>
              <img className={styles.preview} src={svgUrl} alt="QR Code" />
            </div>
            <div className={styles.actionBox}>
              <div className={formStyles.formRow}>
                <h4 className={styles.header}>Download formats:</h4>
                <DownloadButton
                  size={values.size}
                  format="svg"
                  onDownload={onDownloadSvg}
                >
                  SVG
                </DownloadButton>
                <DownloadButton
                  size={values.size}
                  format="image/png"
                  onDownload={onDownload}
                >
                  PNG
                </DownloadButton>
                <DownloadButton
                  size={values.size}
                  format="image/jpeg"
                  quality={1}
                  onDownload={onDownload}
                >
                  JPEG
                </DownloadButton>
              </div>
              <div className={formStyles.formRow}>
                <h4 className={styles.header}>Short link:</h4>
                <input
                  value={shortLink}
                  className={styles.input}
                  type="url"
                  ref={shortUrlRef}
                  readOnly
                />
                <button
                  type="button"
                  className={formStyles.button}
                  onClick={onCopy}
                >
                  Copy
                </button>
              </div>
              <Field name="email" validateFields={[]} initialValue={user ? user.email : ''}>
                {({ input, meta: { error, submitError, touched } }) => (
                  <div className={cn(formStyles.formRow, (error || submitError) && touched && formStyles.hasError)}>
                    <h4 className={styles.header}>Send to email:</h4>
                    <input
                      {...input}
                      className={styles.input}
                      type="email"
                      disabled={submitting}
                      placeholder="Email"
                    />
                    <button
                      type="submit"
                      className={formStyles.primaryButton}
                      disabled={submitting}
                    >
                      {submitting? 'Sending...' : 'Send'}
                    </button>
                    {(error || submitError) && touched && (
                      <div className={formStyles.formError}>{error || submitError}</div>
                    )}
                  </div>
                )}
              </Field>
            </div>
            <canvas width={values.size} height={values.size} className={styles.canvas} ref={canvasRef}></canvas>
          </form>
        )} />
      
    </div>
  ) : null;
};
