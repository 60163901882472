import React from 'react';
import cn from 'classnames';

import { ReactComponent } from './small-spinner.svg';

import styles from './SmallSpinner.module.css'
import './SmallSpinner.css';

export const SmallSpinner = ({ visible, className, width = 12, height = 12, showOverlay, children }) => visible ? (
  <div className={cn(styles.overlay, showOverlay && styles.showOverlay)}>
    <ReactComponent
      className={className}
      width={width}
      height={height}
    />
    {children && <span className={styles.label}>{children}</span>}
  </div>
) : null;
