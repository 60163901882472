import React from 'react';
import ReactDOM from 'react-dom';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import './reset.css';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { App } from './components/App';
import * as serviceWorker from './serviceWorker';

const firebaseConfig = {
  apiKey: 'AIzaSyBqQuN7_Z2m15zMYY6Fc7SvMi6kbLXj-K0',
  authDomain: 'hyper-link-shortner.firebaseapp.com',
  databaseURL: 'https://hyper-link-shortner.firebaseio.com',
  projectId: 'hyper-link-shortner',
  storageBucket: 'hyper-link-shortner.appspot.com',
  messagingSenderId: '1073361021905',
  appId: '1:1073361021905:web:57e01456113305ca3f0ef0',
  measurementId: 'G-K72CBQ5XP7',
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
