import escapeHtml from 'escape-html'
import { Text } from 'slate'

const serializeText = node => {
  const text = escapeHtml(node.text).replace(/\r\n|\n|\r/gm, '<br />');

  if (node.italic && node.bold) {
    return `<strong><em>${text}</em></strong>`;
  }

  if (node.bold) {
    return `<strong>${text}</strong>`;
  }

  if (node.italic) {
    return `<em>${text}</em>`;
  }

  if (node.bold) {
    return `<strong>${text}</strong>`;
  }

  return text;
}

export const serialize = node => {
  if (Text.isText(node)) {
    return serializeText(node);
  }

  const children = node.children.map(n => serialize(n)).join('');

  switch (node.type) {
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'heading-one':
      return `<h1>${children}</h1>`;
    case 'heading-two':
      return `<h2>${children}</h2>`;
    case 'heading-three':
      return `<h3>${children}</h3>`;
    case 'list-item':
      return `<li>${children}</li>`;
    case 'numbered-list':
      return `<ol>${children}</ol>`;
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case 'paragraph':
    default:
      return `<p>${children}</p>`;
  }
};