import React from 'react';
import cn from 'classnames';

import { ReactComponent as ExternalLinkIcon } from '../../assets/external-link.svg';
import styles from './ExternalLink.module.css';

export const ExternalLink = ({ href, children, className }) => (
  <a className={cn(styles.externalLink, className)} target="_blank" href={href}>
    {children || href}
    <ExternalLinkIcon className={styles.externalLinkIcon} />
  </a>
);
