import React from 'react';
import cn from 'classnames';
import { Error as ErrorMessage, Warning } from '../Message';

import styles from './Form.module.css';

export const TextInput = ({ input, meta, type, warning, className, errorClassName, ...other }) => {
  const { error, submitError, touched } = meta;
  const invalid = Boolean((error || submitError) && touched);

  return (
    <div className={styles.inputContainer}>
      <input
        {...input}
        {...other}
        className={cn(styles.input, className, invalid && styles.invalid)}
        type={type || 'text'}
      />
      {warning && !error && !submitError && (
        <Warning>{warning}</Warning>
      )}
      {invalid && (
        <ErrorMessage className={errorClassName}>
          {error || submitError}
        </ErrorMessage>
      )}
    </div>
  )
};
