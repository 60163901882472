import React, { useEffect, useContext, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { createForm } from 'final-form';
import { post } from '../../services/HTTPService';
import { useGetRequest, usePostRequest } from '../../hooks/useRequest';
import { UserContext } from '../App';
import { DataSection } from '../DataSection';
import {
  TextInput,
  formStyles,
  validZooplaLink,
  validRightmoveLink,
} from '../Form';
import { ShortLinkInput } from '../ShortLinkInput';
import { RichTextEditor, serialize } from '../RichTextEditor';
import { Spinner } from '../Spinner';
import { errorNotification } from '../Notification';
import { getPropertyDetailsBlock, getAddressBlock } from './template';
import styles from './ProQRForm.module.css';

const initialValue = [
  {
    type: 'paragraph',
    children: [
      { text: 'A line of text in a paragraph.' }
    ],
  },
];


export const ProQRForm = () => {
  const user = useContext(UserContext);
  const [form, setForm] = useState(null);
  const onSubmit = async values => {
    const htmlContent = values.content.map(serialize).join('');

    try {
      const res = await post('/qr-code', { ...values, content: htmlContent });
    } catch(e) {
      errorNotification(e.message);
    }
  };
  const [
    requestProperyDetails,
    loadingPropertyDetails,
    propertyDetails,
  ] = usePostRequest('/property-details');
  const fetchPropertyDetails = async url => {
    await requestProperyDetails({ url });
  };

  const onFetchClick = field => () => {
    const { value } = form.getFieldState(field);

    fetchPropertyDetails(value);
  };
  const isValid = field => {
    const fieldState = form.getFieldState(field);

    return fieldState && fieldState.value && fieldState.valid;
  };
  const [requestUrlId, loadingUrlId, res] = useGetRequest('/generate-id');

  useEffect(() => {
    setForm(createForm({ onSubmit }));
    requestUrlId();
  }, []);

  useEffect(() => {
    if (!form || !user || !user.pro) {
      return;
    }

    form.batch(() => {
      form.change('phoneNumber', user.phoneNumber);
      form.change('email', user.email);
      form.change('content', getAddressBlock({ ...user, blankLine: true }));
    });
  }, [form, user]);

  useEffect(() => {
    if (!propertyDetails) {
      return;
    }
    const temp = getPropertyDetailsBlock({ ...propertyDetails, user });

    form.change('content', temp);
  }, [propertyDetails])

  return form && (
      <FinalForm form={form}>
        {({ handleSubmit }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <DataSection title="Short link">
              {res && res.urlId && (
                <ShortLinkInput
                  name="urlId"
                  formApi={form}
                  className={styles.shortLink}
                  containerClassName={styles.shortLinkContainer}
                  initialValue={res.urlId}
                />
              )}
            </DataSection>
            <DataSection title="Contact details">
              <Field
                name="email"
                component={TextInput}
                validateFields={[]}
                className={styles.input}
                placeholder="Email"
              />
              <Field
                name="phoneNumber"
                component={TextInput}
                validateFields={[]}
                className={styles.input}
                placeholder="Phone number"
              />
            </DataSection>
            <DataSection title="Links">
              <Field
                name="zooplaLink"
                component={TextInput}
                validateFields={[]}
                className={styles.input}
                placeholder="Zoopla link"
                validate={validZooplaLink}
              />
              <Field
                name="rightmoveLink"
                component={TextInput}
                validateFields={[]}
                className={styles.input}
                placeholder="Rightmove link"
                validate={validRightmoveLink}
              />
            </DataSection>
            <DataSection title="Landing page content">
              <Field
                name="content"
                className={styles.wysiwyg}
                initialValue={initialValue}
                component={RichTextEditor}
                validateFields={[]}
                isZooplaActive={isValid('zooplaLink')}
                onZooplaClick={onFetchClick('zooplaLink')}
                isRightmoveActive={isValid('rightmoveLink')}
                onRightmoveClick={onFetchClick('rightmoveLink')}
                loading={loadingPropertyDetails}
              />
            </DataSection>
            <DataSection>
              <button
                className={formStyles.primaryButton}
                type="submit"
              >
                Submit
              </button>
              <button
                className={formStyles.button}
              >
                Cancel
              </button>
            </DataSection>
            <Spinner visible={loadingUrlId} />
          </form>
        )}
      </FinalForm>
  );
};
