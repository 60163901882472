import React from 'react';
import cn from 'classnames';

import { ReactComponent as BoldIcon } from '../../assets/bold.svg';
import { ReactComponent as ItalicIcon } from '../../assets/italic.svg';
import { ReactComponent as LinkIcon } from '../../assets/link.svg';
import { ReactComponent as Heading1Icon } from '../../assets/heading1.svg';
import { ReactComponent as Heading2Icon } from '../../assets/heading2.svg';
import { ReactComponent as Heading3Icon } from '../../assets/heading3.svg';
import { ReactComponent as UnorderedListIcon } from '../../assets/unordered-list.svg';
import { ReactComponent as OrderedListIcon } from '../../assets/ordered-list.svg';
import { ReactComponent as ZooplaIcon } from '../../assets/zoopla.svg';
import { ReactComponent as RightMoveIcon } from '../../assets/rightmove.svg';

import styles from './RichTextEditor.module.css';

export const Icon = ({ name }) => {
  switch(name) {
    case 'bold':
      return <BoldIcon width={11} />;
    case 'italic':
      return <ItalicIcon width={9} />;
    case 'link':
      return <LinkIcon width={13} />;
    case 'heading1':
      return <Heading1Icon width={22} />;
    case 'heading2':
      return <Heading2Icon width={22} />;
    case 'heading3':
      return <Heading3Icon width={22} />;
    case 'unordered-list':
      return <UnorderedListIcon width={20} />;
    case 'ordered-list':
      return <OrderedListIcon width={20} />;
    case 'zoopla':
      return <ZooplaIcon width={20} />;
    case 'rightmove':
      return <RightMoveIcon width={20} />
    default:
      return null;
  }
};

export const ToolbarButton = ({ icon, title, className, active, onClick, ...props }) => {
  const onMouseDown = e => {
    e.preventDefault();
    onClick();
  };

  return (
    <button
      {...props}
      onMouseDown={onMouseDown}
      title={title || icon}
      className={cn(styles.toolbarButton, active && styles.active)}
    >
      <Icon name={icon} />
    </button>
  );
};

export const Toolbar = ({ className, ...props }) => (
  <menu
    {...props}
    className={cn(styles.toolbar, className)}
  />
);
