import React, { useState, useMemo } from 'react';
import dateFormat from 'dateformat';
import {
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
  VictoryZoomContainer,
  VictoryBrushContainer,
  VictoryClipContainer,
  VictoryTooltip,
  VictoryLine,
  VictoryScatter,
  VictoryArea,
  VictoryGroup,
} from 'victory';

import styles from './Chart.module.css';

const DAY_MS = 1000 * 60 * 60 * 24;
const YAER_MS = DAY_MS * 365;

const fontFamily = '\'Source Sans Pro\', sans-serif';
const gridStyles = {
  fill: 'none',
  stroke: '#D8D8D8',
  strokeDasharray: '10, 5',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  pointerEvents: 'painted'
};
const axisStyles = {
  axis: { stroke: '#756f6a' },
  ticks: { stroke: '756f6a', size: 5 },
  tickLabels: { fontFamily, fontSize: 12, padding: 5 },
};
const getRandomInt = (from, to) => {
  const min = Math.ceil(from);
  const max = Math.floor(to);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const Chart = ({ hits }) => {
  const [zoomDomain, setZoomDomain] = useState({ x: [new Date(Date.now() - (30 * DAY_MS)), new Date()] });
  const data = useMemo(() => {
    if (!hits) {
      return [];
    }
    
    const counted = hits.reduce((result, current) => {
      const key = dateFormat(current, 'd mmm yyyy');

      if (key in result) {
        result[key] += 1;
      } else {
        result[key] = 1;
      }
      return result;
    }, {});
    const today = Date.now();
    const result = [];

    for (let i = 182;i >= 0;i--) {
      const current = today - (i * DAY_MS);
      const formatted = dateFormat(current, 'd mmm yyyy');
      const date = new Date(current);

      date.setHours(0, 0, 0, 0);

      result.push({
        date,
        formatted,
        hits: formatted in counted ? counted[formatted] : 0,
        // hits: getRandomInt(0, 50),
      });
    }

    return result;
  }, [hits]);

  return (
    <div className={styles.chart}>
      <VictoryChart
        width={730}
        height={400}
        domainPadding={40}
        scale={{ x: "time" }}
        style={{
          parent: { fontFamily, padding: 0 }
        }}
        padding={{ left: 60, right: 60, top: 25, bottom: 25 }}
        containerComponent={
          <VictoryZoomContainer
            zoomDimension="x"
            zoomDomain={zoomDomain}
            onZoomDomainChange={setZoomDomain}
            allowZoom={false}
            clipContainerComponent={<VictoryClipContainer clipPadding={{ right: 30 }} />}
          />
        }
      >
        <VictoryAxis
          tickFormat={val => dateFormat(val, 'd mmm')}
          tickLabelComponent={<VictoryLabel textAnchor="middle" />}
          style={{ ...axisStyles, grid: gridStyles }}
        />
        <VictoryAxis
          dependentAxis
          tickValues={hits.length === 0 ? [0, 1, 2, 3, 4] : undefined}
          style={{ ...axisStyles, grid: gridStyles }}
          minDomain={1}
        />
        <VictoryGroup
          labels={({ datum }) => datum.hits}
          labelComponent={
            <VictoryTooltip
              style={{ fontSize: 10 }}
              pointerOrientation="bottom"
            />
          }
        >
          <VictoryArea
            style={{
              data: { fill: "rgba(49, 159, 196, 0.39)", stroke: "rgb(49, 159, 196)", strokeWidth: 2 }
            }}
            data={data}
            x="date"
            y="hits"
          />
          <VictoryScatter
            data={data}
            x="date"
            y="hits"
            size={5}
            style={{ data: { fill: "rgb(49, 159, 196)" } }}
          />
        </VictoryGroup>
      </VictoryChart>
      <VictoryChart
        padding={{ top: 0, left: 60, right: 60, bottom: 30 }}
        width={730}
        height={100}
        scale={{ x: "time" }}
        containerComponent={
          <VictoryBrushContainer
            brushDimension="x"
            brushDomain={zoomDomain}
            onBrushDomainChange={setZoomDomain}
            defaultBrushArea="move"
            allowResize={false}
          />
        }
      >
        <VictoryAxis
          tickFormat={val => dateFormat(val, 'd mmm')}
          tickLabelComponent={<VictoryLabel textAnchor="middle" />}
          style={axisStyles}
        />
        <VictoryLine
          interpolation="linear"
          data={data}
          x="date"
          y="hits"
          style={{ data: { stroke: "rgb(49, 159, 196)" } }}
        />
      </VictoryChart>
    </div>
  )
};
