import { jsx } from 'slate-hyperscript';

export const deserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  }

  const children = Array.from(el.childNodes).map(deserialize)

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BR':
      return '\n';
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    case 'STRONG':
    case 'B':
      return jsx('text', { bold: true }, children);
    case 'EM':
    case 'I':
      return jsx('text', { italic: true }, children);
    case 'A':
      return jsx(
        'element',
        { type: 'link', url: el.getAttribute('href') },
        children
      );
    
    default:
      return el.textContent;
  }
};
