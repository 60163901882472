/** @jsx jsx */
import { jsx } from 'slate-hyperscript';
import { deserialize } from '../RichTextEditor';

export const getPropertyDetailsBlock = ({ title, address, features, price, tenure, description, lettingInfo, user }) => {
  const doc = new DOMParser().parseFromString(description, 'text/html');
  const desc = deserialize(doc.body);
  const { branchName, addressLine1, addressLine2, town, postcode } = user;
  const addressBlock = getAddressBlock({ branchName, addressLine1, addressLine2, town, postcode });

  return (
    <fragment>
      <element type="heading-one">{title}</element>
      <element type="heading-two">{address}</element>
      {features && features.length && (
        <element type="bulleted-list">
          {features.map(feature => <element type="list-item">{feature}</element>)}
        </element>
      )}
      {price && (
        <element type="paragraph">
          <text bold>Price: </text>
          {price}
        </element>
      )}
      {lettingInfo && lettingInfo.length && (
        <element type="paragraph">
          {lettingInfo.map(({ label, value }, i) => (
            <fragment>
              <text bold>{label} </text>
              {value}
              {i !== (lettingInfo.length - 1) && '\n'}
            </fragment>
          ))}
        </element>
      )}
      {tenure && (
        <element type="paragraph">
          <text bold>Tenure: </text>
          {tenure}
        </element>
      )}
      {desc && (
        <element type="paragraph">
          {desc}
        </element>
      )}
      {addressBlock}
    </fragment>
  );
};

const GOOGLE_MAPS_SEARCH_URL = 'https://www.google.com/maps/search/?api=1'
const GOOGLE_MAPS_DIR_URL = 'https://www.google.com/maps/dir/?api=1';

export const getAddressBlock = ({ branchName, addressLine1, addressLine2, town, postcode, blankLine, formattedAddress, placeId }) => {
  const viewMapLink = `${GOOGLE_MAPS_SEARCH_URL}&query=${formattedAddress}&query_place_id=${placeId}`;
  const getDirectionsLink = `${GOOGLE_MAPS_DIR_URL}&destination=${formattedAddress}&destination_place_id=${placeId}`;

  return (
    <fragment>
      {blankLine && <element type="paragraph"> </element>}
      <element type="paragraph">
        <text bold>{branchName + '\n'}</text>
        {addressLine1 + '\n'}
        {addressLine2 ? addressLine2 + '\n' : ''}
        {town + '\n'}
        {postcode}
      </element>
      <element type="paragraph">
        <element type="link" url={encodeURI(viewMapLink)}>View map location</element>
      </element>
      <element type="paragraph">
        <element type="link" url={encodeURI(getDirectionsLink)}>Get directions in Google Maps</element>
      </element>
    </fragment>
  );
};
