import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import cn from 'classnames';
import {
  TextInput,
  Textarea,
  composeValidators,
  minLength,
  maxLength,
  formStyles,
} from '../Form';
import { DataSection } from '../DataSection';

import styles from './PageDetails.module.css';

export const PageDetails = ({ response, formApi, values, submitting }) => {
  const [editMode, setEditMode] = useState(false);
  const { title, image, description } = response;
  const shouldShowAddButton = () => {
    return !title &&
           !description &&
           !image &&
           !editMode &&
           !values.title &&
           !values.description;
  };
  const onEditClick = e => {
    setEditMode(true);
    e.preventDefault();
  };
  const onSave = e => {
    setEditMode(false);
    e.preventDefault();
  };
  const onCancel = e => {
    e.preventDefault();
    formApi.change('title', title);
    formApi.resetFieldState('title');
    formApi.change('description', description);
    formApi.resetFieldState('description');
    setEditMode(false);
  };
  const isSaveDisabled = () => {
    const titleState = formApi.getFieldState('title');
    const descriptionState = formApi.getFieldState('description');
    
    return (titleState && titleState.invalid) || (descriptionState && descriptionState.invalid);
  };

  useEffect(() => {
    if (submitting) {
      setEditMode(false);
    }
  }, [submitting])

  return shouldShowAddButton() ? (
    <div className={formStyles.formRow}>
      <a className={styles.addButton} href="/add-page-details" onClick={onEditClick}>Add page details</a>
    </div>
  ) : (
    <div className={cn(styles.pageDetails, editMode && styles.editMode)}>
      {image && !editMode && (
        <img
          className={styles.pageImage}
          alt="page image"
          src={image}
        />
      )}
      {editMode ? (
        <DataSection title="Title">
          <Field
            name="title"
            initialValue={title}
            validate={composeValidators(maxLength(128), minLength('Title', 3))}
            className={styles.input}
            errorClassName="noIcon"
            component={TextInput}
            validateFields={[]}
          />
        </DataSection>
      ) : (
        <h3 className={styles.pageTitle}>{values.title || title}</h3>
      )}
      {editMode ? (
        <DataSection title="Description">
          <Field
            name="description"
            initialValue={description}
            validate={maxLength(256)}
            className={styles.textarea}
            errorClassName="noIcon"
            component={Textarea}
            validateFields={[]}
          />
        </DataSection>
      ) : (
        <p title={values.description || description} className={styles.pageDesc}>
          {values.description || description}
        </p>
      )}
      {editMode ? (
        <div className={formStyles.formButtonsRow}>
          <button
            className={formStyles.primaryButton}
            onClick={onSave}
            disabled={isSaveDisabled()}
          >
            Save
          </button>
          <button className={formStyles.button} onClick={onCancel}>Cancel</button>
        </div>
      ) : (
        <a href="/" onClick={onEditClick}>Edit page details</a>
      )}
    </div>
  );
};
