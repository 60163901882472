import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ReactComponent as WarningIcon } from '../../assets/warning.svg';
import { ReactComponent as ErrorIcon } from '../../assets/error.svg';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';

import styles from './Message.module.css';

const getIconComponent = type => {
  switch (type) {
    case 'warning': 
      return WarningIcon;
    case 'error':
      return ErrorIcon;
    case 'info':
      return InfoIcon;
  }

  return null;
}

export const Message = ({ type, className, children }) => {
  const Icon = getIconComponent(type);

  return (
    <p className={cn(styles.message, className, styles[type])}>
      <Icon className={styles.icon} />
      <span>{children}</span>
    </p>
  );
};

Message.propTypes = {
  type: PropTypes.oneOf(['warning', 'error', 'info']),
};

export const Warning = ({ children, ...other }) => (
  <Message type="warning" {...other}>{children}</Message>
);
export const Error = ({ children, ...other }) => (
  <Message type="error" {...other}>{children}</Message>
);
export const Info = ({ children, ...other }) => (
  <Message type="info" {...other}>{children}</Message>
);
