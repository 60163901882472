import React from 'react';
import cn from 'classnames';

import styles from './Spinner.module.css';

export const Spinner = ({ visible, hideOverlay }) => visible ? (
  <div className={cn(styles.spinnerOverlay, hideOverlay && styles.hideOverlay)}>
    <i className={styles.spinner} role="image"></i>
  </div>
) : null;
