import React, { useMemo, useState } from 'react';
import dateFormat from 'dateformat';
import { Link } from '@reach/router';
import { ExternalLink } from '../ExternalLink';
import { svgUrlBase64 } from '../../utils/ImageUtils';

import styles from './UserQRCodesPage.module.css';

export const UserQRCodeItem = ({ shortLink, longLink, qrCode, title, description, createdAt, urlId }) => {
  const [showFullLink, setShowFullLink] = useState(false);
  const svgSrc = useMemo(() => qrCode && svgUrlBase64(qrCode), [qrCode]);
  const date = useMemo(() => createdAt && dateFormat(createdAt, 'd mmm yyyy'), [createdAt]);
  const onToggleFullLink = e => {
    e.preventDefault();
    setShowFullLink(!showFullLink);
  };

  return (
    <div className={styles.qrCodeItem}>
      <h3 className={styles.itemHeader}>{shortLink.replace('http://localhost:5001', 'qrbox.uk')}</h3>
      <Link to={`/qr-code/${urlId}`}>
        <img className={styles.itemImage} alt="qr code" src={svgSrc} />
      </Link>
      <h4 className={styles.itemTitle}>{title}</h4>
      <p className={styles.itemDescription}>{description}</p>
      {showFullLink && (
        <ExternalLink className={styles.longLink} href={longLink} />
      )}
      <footer className={styles.itemFooter}>
        <a onClick={onToggleFullLink} href="/show-full-link">
          {!showFullLink ? 'Show full link' : 'Hide full link'}
        </a>
        <p>{date}</p>
      </footer>
    </div>
  );
};
