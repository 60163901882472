import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { createForm } from 'final-form';
import { OnChange } from 'react-final-form-listeners';
import { useNavigate } from '@reach/router';
import { put, del } from '../../services/HTTPService';
import { UserContext } from '../App';
import { ExternalLink } from '../ExternalLink';
import { DataSection } from '../DataSection';
import { SmallSpinner } from '../SmallSpinner';
import { Spinner } from '../Spinner';
import { successNotification, errorNotification } from '../Notification';
import {
  TextInput,
  Textarea,
  validUrl,
  composeValidators,
  minLength,
  maxLength,
  formStyles,
} from '../Form';
import { usePageMetadata } from './usePageMetadata';

import styles from './UrlEditForm.module.css';

export const UrlEditForm = ({ response }) => {
  const user = useContext(UserContext);
  const [editMode, setEditMode] = useState(false);
  const [submitResponse, setSubmitResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const values = useMemo(() => ({...response, ...submitResponse}), [response, submitResponse]);
  const form = useRef(null);
  const onEditClick = e => {
    e.preventDefault();
    setEditMode(true);
  };
  const onSubmit = async values => {
    try {
      const res = await put(`/shortlink/${response.urlId}`, values);

      if (res.error) {
        throw new Error(res.error);
      }

      setSubmitResponse(res);
      setEditMode(false);
    } catch (e) {
      return { longLink: e.message };
    }
  };
  const {
    pageMeta,
    requestPageMeta,
    loadingPageMeta,
    cancelRequest,
    resetResponse,
  } = usePageMetadata(values.longLink);
  const onCancelClick = e => {
    e.preventDefault();
    form.current.reset();
    resetResponse();
    setEditMode(false);
  };
  const onCancelRequest = e => {
    e.preventDefault();
    cancelRequest();
  };
  const onDelete = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await del(`/shortlink/${response.urlId}`);

      if (res.error) {
        throw new Error(res.error);
      }

      navigate('/my-qr-codes');
      successNotification('QR Code has been deleted successfully');
    } catch (e) {
      errorNotification(e.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    form.current = createForm({ onSubmit });
  }, []);

  useEffect(() => {
    if (pageMeta === null) {
      return;
    }
    form.current.batch(() => {
      form.current.change('title', pageMeta.title);
      form.current.change('description', pageMeta.description);
    });
  }, [pageMeta]);

  return form.current && (
    <FinalForm form={form.current}>
      {({ handleSubmit, submitting }) => (
        <form className={styles.form} onSubmit={handleSubmit} disabled={submitting}>
          <header className={styles.formHeader}>
            <h1 className={styles.title}>QR Code</h1>
            {!response.public && user && (
              <div className={styles.actionsContainer}>
                {editMode ? (
                  <>
                    <button
                      type="submit"
                      className={formStyles.smallPrimaryButton}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className={formStyles.smallButton}
                      onClick={onCancelClick}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className={formStyles.smallButton}
                      onClick={onEditClick}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className={formStyles.smallDangerButton}
                      onClick={onDelete}
                    >
                      Delete
                    </button>
                  </>
                )}
              </div>
            )}
          </header>
          <DataSection title="Original link">
            {editMode ? (
              <Field
                name="longLink"
                initialValue={values.longLink}
                validate={validUrl}
                component={TextInput}
                className={styles.input}
                warning={pageMeta && pageMeta.error}
              />
            ) : (
              <ExternalLink href={values.longLink} />
            )}
            <OnChange name="longLink">{requestPageMeta}</OnChange>
          </DataSection>
          <div className={styles.pageMetadata}>
            {(values.title || editMode) && (
              <DataSection title="Title">
                {editMode ? (
                  <Field
                    name="title"
                    initialValue={values.title}
                    validate={composeValidators(maxLength(128), minLength('Title', 3))}
                    component={TextInput}
                    className={styles.input}
                  />
                ) : (
                  <p>{values.title}</p>
                )}
              </DataSection>
            )}
            {(values.description || editMode) && (
              <DataSection title="Description">
                {editMode ? (
                  <Field
                    name="description"
                    initialValue={values.description}
                    validate={maxLength(256)}
                    component={Textarea}
                    className={styles.textarea}
                  />
                ) : (
                  <p>{values.description}</p>
                )}
              </DataSection>
            )}
            <SmallSpinner showOverlay visible={loadingPageMeta}>
              Requesting page details <a href="/" onClick={onCancelRequest}>Cancel</a>
            </SmallSpinner>
          </div>
          <SmallSpinner showOverlay visible={submitting}>
            Saving changes
          </SmallSpinner>
          <Spinner visible={loading} />
        </form>
      )}
    </FinalForm>
  );
};
