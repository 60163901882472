import React, { useState, useEffect, useContext } from 'react';
import { get } from '../../services/HTTPService';
import { Spinner } from '../Spinner';
import { UserContext } from '../App';
import { UserQRCodeItem } from './UserQRCodeItem';

import styles from './UserQRCodesPage.module.css';

export const UserQRCodesPage = ({ onSignIn }) => {
  const [userLinks, setUserLinks] = useState(null);
  const [loading, setLoading] = useState(true);
  const user = useContext(UserContext);
  const loadUserLinks = async () => {
    setLoading(true);
    try {
      const links = await get('/user-qr-codes');

      setUserLinks(links.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1));
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      !userLinks && loadUserLinks();
    }
    if (user === null) {
      setLoading(false);
    }
  }, [user]);

  return (
    <div className={styles.userQRCodes}>
      {user === null ? (
        <p>Please <a href="/sign-in" onClick={onSignIn}>sign in</a> in order to view your QR codes.</p>
      ) : 
        !userLinks || userLinks.length === 0 ? (
          <div>Nothing here yet.</div>
        ) : (
          userLinks.map(link => <UserQRCodeItem {...link} />)
      )}
      <Spinner visible={loading} />
    </div>
  )
};
