import React, { useState, useEffect, useMemo } from 'react';
import { QRCodeBox } from '../QRCodeBox';
import { Spinner } from '../Spinner';
import { Chart } from '../Chart';
import { Hero } from '../Hero';
import { DataSection } from '../DataSection';
import { UrlEditForm } from '../UrlEditForm';
import { get } from '../../services/HTTPService';

import 'cal-heatmap/cal-heatmap.css';
import styles from './QRCodePage.module.css';

const StatisticsItem = ({ label, children }) => (
  <div className={styles.statisticsItem}>
    <div className={styles.label}>{label}</div>
    <div className={styles.value}>{children}</div>
  </div>
);

const DAY_MS = 1000 * 60 * 60 * 24;

export const QRCodePage = ({ urlId, user }) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const loadQRCodeData = async () => {
    setLoading(true);
    try {
      const res = await get(`/shortlink/${urlId}`);

      if (res.error) {
        throw new Error(res.error);
      }
      
      setResponse(res);
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };
  const statistics = useMemo(() => {
    if (!response || !response.hits) {
      return {};
    }
    const { hits } = response;
    const startOfToday = new Date();

    startOfToday.setHours(0, 0, 0, 0);

    const startOfTodayMs = startOfToday.getTime();
    const sevenDaysAgoMs = startOfTodayMs - (DAY_MS * 6);

    return {
      today: hits.filter(hit => hit >= startOfTodayMs).length,
      lastSevenDays: hits.filter(hit => hit >= sevenDaysAgoMs).length,
      total: hits.length,
    };
  }, [response]);

  useEffect(() => {
    !response && loadQRCodeData();
  }, [user]);

  return (
    <div className={styles.qrCodePage}>
      {response ? (
        <>
          <UrlEditForm response={response} />
          <DataSection>
            <QRCodeBox
              svgString={response.qrCode}
              shortLink={response.shortLink}
              urlId={response.urlId}
              user={user}
            />
          </DataSection>
          <DataSection title="Statistics">
            <div className={styles.statistics}>
              <StatisticsItem label="Total">{statistics.total}</StatisticsItem>
              <StatisticsItem label="Today">{statistics.today}</StatisticsItem>
              <StatisticsItem label="Last 7 days">{statistics.lastSevenDays}</StatisticsItem>
            </div>
            <Chart hits={response.hits} />
          </DataSection>
        </>
      ) : (
        <p>Nothing here yet</p>
      )}
      {error && error === 'Not authorized' && (
        <Hero title="401" subtitle={error} />
      )}
      {error && error === 'Not found' && (
        <Hero title="404" subtitle={error} />
      )}
      <Spinner visible={loading} />
    </div>
  )
};
