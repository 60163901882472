import { useState, useMemo } from 'react';
import pDebounce from 'p-debounce';
import { post } from '../../services/HTTPService';

export const usePageMetadata = initialValue => {
  const [res, setRes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState(null);
  const request = useMemo(() => {
    let controller;
    let signal;
    return pDebounce(async (value) => {
      if (controller !== undefined) {
        // Cancel the previous request
        controller.abort();
      }

      if (value === initialValue || !value) {
        return;
      }
      setRes(null);
      setLoading(true);

      if ('AbortController' in window) {
        controller = new AbortController();
        signal = controller.signal;
        setController(controller);
      }
    
      try {
        const res = await post('/page-metadata', { url: value }, { signal });

        setRes(res);
      } catch (e) {
        e.message.indexOf('aborted') === -1 && setRes({ error: e });
      }
      setLoading(false);
      setController(null);
    }, 600);
  }, [initialValue]);
  const resetResponse = () => {
    setRes(null);
    controller && controller.abort();
    setController(null);
    setLoading(false);
  };

  return {
    pageMeta: res,
    requestPageMeta: request,
    loadingPageMeta: loading,
    cancelRequest: () => controller && controller.abort(),
    resetResponse,
  };
};
