import React, { useContext, useState } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { useNavigate } from '@reach/router';
import { post } from '../../services/HTTPService';
import { sendSignInLink } from '../../utils/AuthUtils';
import { UserContext } from '../App';
import { DataSection } from '../DataSection';
import {
  TextInput,
  validEmail,
  validPhoneNumber,
  validPostcode,
  required,
  formStyles,
} from '../Form';
import { successNotification, errorNotification } from '../Notification';
import { Spinner } from '../Spinner';
import { SignUpSuccessMessage } from '../SignUpModal';

import styles from './ApplyForPro.module.css';

export const ApplyForPro = () => {
  const user = useContext(UserContext);
  const [sentToEmail, setSentToEmail] = useState('');
  const navigate = useNavigate();
  const signIn = async email => {
    try {
      await sendSignInLink(email);
      setSentToEmail(email);
    } catch(e) {
      return { email: e.message };
    }
  };
  const onSubmit = async values => {
    try {
      const res = await post('/pro-account', values);

      if (res.error && res.error.unknown) {
        throw new Error(res.error.unknown);
      }

      if (res.error) {
        return res.error;
      }

      successNotification('Your pro account has been created successfully.');

      if (user) {
        return navigate('/pro');
      }

      return await signIn(values.email);
    } catch (e) {
      errorNotification(e.message);
    }
  };

  return !sentToEmail ? (
    <FinalForm onSubmit={onSubmit} render={({ handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit} className={styles.form}>
        <h1 className={styles.formTitle}>Pro Account Application Form</h1>
        <DataSection className={styles.formRow} title="Branch name" >
          <Field
            className={styles.input}
            name="branchName"
            component={TextInput}
            validate={required}
          />
        </DataSection>
        <DataSection className={styles.formRow} title="Email">
          {(user || user === null) && (
            <Field
              className={styles.input}
              name="email"
              component={TextInput}
              validate={validEmail}
              initialValue={user ? user.email : ''}
              disabled={!!user}
            />
          )}
        </DataSection>
        <DataSection className={styles.formRow} title="Phone number">
          <Field
            className={styles.input}
            name="phoneNumber"
            component={TextInput}
            validate={validPhoneNumber}
          />
        </DataSection>
        <DataSection className={styles.formRow} title="Branch address">
          <Field
            className={styles.input}
            name="addressLine1"
            component={TextInput}
            placeholder="Address line 1"
            validate={required}
          />
          <Field
            className={styles.input}
            name="addressLine2"
            component={TextInput}
            placeholder="Address line 2"
          />
          <Field
            className={styles.input}
            name="town"
            component={TextInput}
            placeholder="Town/City"
            validate={required}
          />
          <Field
            className={styles.input}
            name="postcode"
            component={TextInput}
            placeholder="Postcode"
            validate={validPostcode}
          />
        </DataSection>
        <DataSection className={styles.formRow}>
          <button type="submit" className={formStyles.primaryButton}>Apply</button>
        </DataSection>
        <Spinner visible={submitting} />
      </form>
    )} />
  ) : (
    <DataSection className={styles.successMessage}>
      <SignUpSuccessMessage email={sentToEmail} />
    </DataSection>
  );
};
