import React, { useEffect } from 'react';
import cn from 'classnames';

import styles from './Modal.module.css';

export const Modal = ({ open, onClose, title, className, children }) => {
  const onCloseClick = e => {
    e.preventDefault();
    onClose();
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add('blurred');
    } else {
      document.body.classList.remove('blurred');
    }
  }, [open]);

  return (
    <div className={cn(styles.modalOverlay, open && styles.open)}>
      <div className={cn(styles.modal, className)}>
        {onClose && <a className={styles.closeButton} onClick={onCloseClick} href="/close-modal">Close</a>}
        {title && <h4 className={styles.title}>{title}</h4>}
        {children}
      </div>
    </div>
  );
};
